import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { RouteInfo } from '@ov-suite/ui';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { getPermissionIds } from '@ov-suite/authguard-angular';
import { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { environment } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  misc = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
    sidebar_mini_active: false,
  };

  location: Location;

  private readonly nativeElement: Node;

  private toggleButton;

  sidebarVisible: boolean;

  public open = true;

  public hover: boolean;

  public menuItems: RouteInfo[] = [];

  @Input() routes: RouteInfo[];

  @Input() projectName = 'ADMINLINK';

  @Output() signOutEvent = new EventEmitter();

  constructor(
    location: Location,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly element: ElementRef,
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = true;

    Hub.listen('token', () => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.menuItems = this.routes;
    this.initPermissions();

    const navbar: HTMLElement = this.element.nativeElement;
    const [body] = (document.getElementsByTagName('body') as unknown) as Element[];
    [this.toggleButton] = (navbar.getElementsByClassName('navbar-toggler') as unknown) as Element[];
    if (body.classList.contains('sidebar-mini')) {
      this.misc.sidebar_mini_active = true;
    }

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const $layer = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
      }
    });
  }

  initPermissions() {
    getPermissionIds()
      .then(permissionIds => {
        const permissions: Record<number, boolean> = {};
        permissionIds.forEach(permissionId => {
          permissions[permissionId] = true;
        });
        return permissions;
      })
      .then(permissions => {
        // Hide items without permission
        this.menuItems.forEach(item => {
          item.children?.forEach(itemChild => {
            if (!itemChild.hidden && itemChild.permissionId) {
              itemChild.hidden = !permissions[itemChild.permissionId] ?? false;
            }
          });
        });
      })
      .then(() => {
        // Hide Labels without displayed children
        this.menuItems.forEach(item => {
          if (!item.hidden) {
            item.hidden = item.children?.some(itemChild => !itemChild.hidden) ?? false;
          }
        });
      })
      .then(() => {
        this.menuItems.forEach(parentLink => {
          parentLink?.children?.forEach(childLink => {
            if (this.router.url.split('?')[0].endsWith(`${childLink.path}`)) {
              parentLink['isCollapsed'] = false;
            } else if (parentLink['isCollapsed'] === undefined) {
              parentLink['isCollapsed'] = true;
            }
          });
        });
      });
  }

  signOut() {
    const federated = !!environment.values.cognito.oauth.domain;
    this.signOutEvent.emit();
    // Auth.SignOut is broken with Federated Login, this is a hack makes it work
    if (federated) {
      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      Auth._storage.setItem('amplify-signin-with-hostedUI', 'true');
    }
    Auth.signOut()
      .then(signOutData => console.log(signOutData))
      .catch(err => console.log(err));

    document.getElementById('auth-frame')['contentWindow'].postMessage(
      {
        action: 'logout',
      },
      '*',
    );

    if (!federated) {
      window.location.href = `${environment.values.webUrl.idm}/auth/login`;
    }
  }

  minimizeSidebar() {
    this.sidebarToggle();
    const body = document.getElementsByTagName('body')[0];
    const { misc } = this;

    if (this.misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
      this.open = true;
    } else {
      setTimeout(() => {
        body.classList.add('sidebar-mini');
        this.open = false;
        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(() => {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  sidebarOpen() {
    const { toggleButton } = this;
    const html = document.getElementsByTagName('html')[0];
    setTimeout(() => {
      toggleButton.classList.add('toggled');
    }, 500);
    const mainPanel = document.getElementsByClassName('main-panel')[0] as HTMLElement;
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    html.classList.add('nav-open');
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
    const mainPanel = document.getElementsByClassName('main-panel')[0] as HTMLElement;

    if (window.innerWidth < 991) {
      setTimeout(() => {
        mainPanel.style.position = '';
      }, 500);
    }
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
}
