import { Component } from '@angular/core';
import { environment } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  loginUrl = `${environment.values.webUrl.idm}/auth/login`;

  login() {
    window.location.href = this.loginUrl;
  }
}
