import { OVTagMethod } from '@ov-suite/ov-metadata';
import { AccessCode as AccessCodeModel } from '@ov-suite/models-yard';
import { Injectable } from '@angular/core';
import { environment } from '@ov-suite/helpers-shared';

@Injectable({
  providedIn: 'root',
})
export class AccessCodeValidatorService {
  @OVTagMethod('validator', AccessCodeModel)
  async validateAccessCode(code: string): Promise<Response> {
    return fetch(
      `${environment.values.apiUrl.yard}/api/access-code/check/${code}`,
    );
  }
}
