<div class="main-content p-4 " *ngIf="data" #report id="report-container">
  <div>
    <button class="btn btn-dark" (click)="download()">Download</button>
  </div>

  <h4>Entry Details</h4>

  <div class="table-sm">
    <table class="table table-striped" aria-describedby="View Process">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Start Time</th>
          <th scope="col">Status</th>
          <th scope="col">Completed</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ data?.id }}</td>
          <td>{{ formatDate(data?.startTime) }}</td>
          <td>
            {{ !data?.ended || !data?.completed ? 'Active' : 'Inactive' }}
          </td>
          <td>{{ data?.completed }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <hr />

  <h5>Tasks</h5>

  <div class="table-sm">
    <table class="table table-striped" aria-describedby="View Process">
      <thead>
      <tr>
        <th scope="col">Start Time</th>
        <th scope="col">Duration</th>
        <th scope="col">Name</th>
        <th scope="col">Assignee</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let task of data?.tasks?.data">
        <td>{{ formatDate(task?.startTime) }}</td>
        <td>{{duration(task?.startTime, task?.endTime)}} ({{ formatDate(task?.endTime) }})</td>
        <td>{{ task?.name }}</td>
        <td>
          {{ task?.assignee }}
        </td>
      </tr>
      </tbody>
    </table>
  </div><hr />

  <h5>Variables</h5>

  <div class="table-sm">
    <table class="table table-striped" aria-describedby="View Process">
      <thead>
      <tr class="w-100">
        <th scope="col">Name</th>
        <th scope="col">Value</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let vari of variables" >
        <td>{{ vari?.name }}</td>
        <td *ngIf="vari?.isArray">
          <table class="w-100">
            <tr>
              <th>Name</th>
              <th>Value</th>
            </tr>
            <tr *ngFor="let i of vari?.value">
              <td>
                {{ i.name }}
              </td>
              <td *ngIf="i.value">
                {{ i.value }}
              </td>
              <td *ngIf="!i.value">
                {{ i | json }}
              </td>
            </tr>
          </table>
        </td>
        <td *ngIf="vari?.isBase64" key="{{vari?.id}}">
          <button class="btn btn-dark" (click)="previewImage()">{{!preview ? 'Preview' : 'Close'}}</button>
          <img class="diagram-viewer"
               [ngClass]="{ 'showImage' : !preview}"
               src="data:image/png;base64,{{vari?.value}}"
               alt="Unable to load" />
        </td>
        <td *ngIf="!vari?.isArray && !vari?.isBase64">
          {{ vari?.value }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <hr />

  <hr />

</div>
