import { Component } from '@angular/core';
import * as L from 'leaflet';
import moment from 'moment';
import { control, latLng, LatLngBounds, LatLngTuple, Map, MapOptions, tileLayer } from 'leaflet';
import { SessionStorage } from '@ov-suite/helpers-angular';
import { Domain } from '@ov-suite/models-admin';
import { OvAutoService } from '@ov-suite/services';
import { Geofence } from '@ov-suite/models-yard';
import { Constants, MapMarkers, Payload } from './live-map-dashboard.constants';
import { VehicleTrackYardService } from '../../../services/vehicle-track/vehicle-track.service';

@Component({
  selector: 'ov-suite-mobile-eod',
  templateUrl: './live-map-dashboard.component.html',
  styleUrls: ['./live-map-dashboard.component.scss'],
})
export class LiveMapDashboardComponent {
  defaultDomainCoordinates: [number, number];
  domainCoordinates: [number, number];

  markers: MapMarkers[] = [];

  parentId?: number;

  // Class - Required
  // formClass = LiveMapDashboard;
  map: Map;

  bounds: LatLngBounds = null;

  // Leaflet Options
  options: MapOptions = {
    layers: [
      tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        maxZoom: 21,
        attribution: '...',
        minZoom: 4,
      }),
    ],
    zoom: 19,
    // center: latLng(-25.516273, 28.184095),
  };

  constructor(private readonly vehicleTrackService: VehicleTrackYardService, private readonly ovAutoService: OvAutoService) {
    const domainId = SessionStorage.getSelectedDomain();
    ovAutoService.get({ entity: Domain, id: Number(domainId) }).then((domain: Domain) => {
      if (domain?.map?.latitude && domain?.map?.longitude) {
        const savedLocation = SessionStorage.getDomainLocation(domainId);
        this.domainCoordinates = [domain.map.latitude, domain.map.longitude];
        this.defaultDomainCoordinates = [domain.map.latitude, domain.map.longitude];

        if (savedLocation) {
          this.domainCoordinates = [savedLocation.lat, savedLocation.lng];
          this.map.setZoom(+savedLocation.zoom);
        }

        this.map.panTo(this.domainCoordinates);
      } else {
        // TODO DEFAULT TO MORE GENERALISED AREA IN SOUTH-AFRICA
        // DEFAULT TO CLAYVILLE (Currently Clover Specific)
        this.map.panTo([-25.96854, 28.226859]);
      }
    });
  }

  getLiveMap(map: Map) {
    this.vehicleTrackService
      .getLiveMap()
      // tslint:disable-next-line:no-any
      .subscribe(
        (res: any) => {
          res.data.getLiveMap.forEach(row => {
            if (this.markers.some(markerToFind => markerToFind.imei === row.imei)) {
              this.updateMarker(row, map);
            } else {
              this.generateMarker(row, map).addTo(map);
            }
          });
        },
        eee => {
          console.error(eee);
        },
      );
  }

  // Fires when map is loaded
  mapReady(map: Map): void {
    this.map = map;
    this.generateLegend().addTo(map);
    // map.addControl(control.zoom({ position: 'bottomright' }));

    this.getLiveMap(map);

    this.vehicleTrackService.listen().subscribe(
      () => {
        this.getLiveMap(map);
      },
      eee => {
        console.error(eee);
      },
    );

    const that = this;
    const ctrl = new L.Control({ position: 'topleft' });
    ctrl.onAdd = (m) => {
      const azoom = L.DomUtil.create('button', 'leaflet-reset-button');
      azoom.innerHTML = '<i aria-hidden="true" class="fa fa-refresh"></i>';
      L.DomEvent.disableClickPropagation(azoom).addListener(
        azoom,
        'click',
        function () {
          const t = that;

          if (t && t.defaultDomainCoordinates) {
            const loc = {
              lng: t.defaultDomainCoordinates[1],
              lat: t.defaultDomainCoordinates[0],
            };
            m.setView(loc, 19);
          }
        },
        azoom,
      );
      return azoom;
    };

    map.addControl(ctrl);
  }

  mapMove(): void {
    const domain = SessionStorage.getSelectedDomain();
    const payload = { ...this.map.getBounds().getCenter(), zoom: this.map.getZoom() };
    if (this.domainCoordinates) {
      SessionStorage.setDomainLocation(domain, JSON.stringify(payload));
    }
  }

  mapZoom(): void {
    const domain = SessionStorage.getSelectedDomain();
    const payload = { ...this.map.getBounds().getCenter(), zoom: this.map.getZoom() };
    if (this.domainCoordinates) {
      SessionStorage.setDomainLocation(domain, JSON.stringify(payload));
    }
  }

  updateMarker(payload: Payload, map: Map) {
    const markerObject = this.markers.find(markerToFind => markerToFind.imei === payload.imei);
    let newPolyline;
    markerObject.marker.setIcon(this.getTruckColor(payload.ragStatus).icon);
    markerObject.marker.setPopupContent(this.generatePopup(payload).getContent().toString());
    markerObject.marker.setLatLng([payload.currentLat, payload.currentLon]);
    markerObject.marker.on('mouseover', () => {
      newPolyline = L.geoJSON(JSON.parse(payload.geojson)).addTo(map);
    });
    markerObject.marker.on('mouseout', () => {
      map.removeLayer(newPolyline);
    });
    this.markers.forEach(markerToReplace => [markerObject].find(newMarker => newMarker.imei === markerToReplace.imei) || markerToReplace);
  }

  generateMarker(payload: Payload, map: Map) {
    let polyLine;
    const coordinates = new L.LatLng(payload.currentLat, payload.currentLon);
    const marker = L.marker(coordinates, this.getTruckColor(payload.ragStatus));
    marker.bindPopup(this.generatePopup(payload), Constants.POPUP_OPTIONS);
    marker.on('mouseover', () => {
      // polyLine = this.generatePolyline(JSON.parse(payload.geojson)).addTo(map);
      polyLine = L.geoJSON(JSON.parse(payload.geojson)).addTo(map);
    });
    marker.on('mouseout', () => {
      map.removeLayer(polyLine);
    });
    this.markers.push(new MapMarkers(payload.imei, marker));

    return marker;
  }

  // tslint:disable-next-line:no-any
  generatePolyline(geoJsons: any) {
    const polylineCoordinates = [];
    geoJsons.forEach(geoJson => {
      geoJson.coordinates.forEach(coordinates => {
        polylineCoordinates.push(coordinates);
      });
    });
    return L.polyline([polylineCoordinates], Constants.POLYLINE_STYLE);
  }

  getTruckColor(status: string) {
    if (status != null) {
      switch (status.toLocaleLowerCase()) {
        case 'green': {
          return Constants.VEHICLE_NO_VIOLATION;
        }
        case 'orange': {
          return Constants.VEHICLE_DELAYED_VIOLATION;
        }
        case 'red': {
          return Constants.VEHICLE_EXCEEDED_TIME_VIOLATION;
        }
        case 'black': {
          return Constants.VEHICLE_EXCEEDED_TOTAL_TIME_VIOLATION;
        }
        default: {
          return Constants.VEHICLE_NO_VIOLATION;
        }
      }
    } else {
      return Constants.VEHICLE_NO_VIOLATION;
    }
  }

  generatePopup(payload: Payload) {
    const popupContent = [];
    const entryTime = `${moment
      .duration(moment().diff(moment(payload.entryTimestamp)))
      .asHours()
      .toFixed(1)} hrs`;

    popupContent.push('<p class="header">Truck Details</p>');
    popupContent.push('<div>');
    popupContent.push('<p class="alignLeft">Access Code:</p>');
    popupContent.push(`<p class="alignRight">${payload.accessCode || 'Not Provided'}</p>`);
    popupContent.push('</div>');
    popupContent.push('<div style="clear: both"></div>');

    popupContent.push('<div>');
    popupContent.push('<p class="alignLeft">Registration:</p>');
    popupContent.push(`<p class="alignRight">${payload.registrationNumber || 'Not Provided'}</p>`);
    popupContent.push('</div>');
    popupContent.push('<div style="clear: both"></div>');

    popupContent.push('<div>');
    popupContent.push('<p class="alignLeft">IMIE:</p>');
    popupContent.push(`<p class="alignRight">${payload.imei}</p>`);
    popupContent.push('</div>');
    popupContent.push('<div style="clear: both"></div>');

    popupContent.push('<div>');
    popupContent.push('<p class="alignLeft">Entered At:</p>');
    popupContent.push(`<p class="alignRight">${moment(payload.entryTimestamp).format('DD/MM/YY hh:mm A')}</p>`);
    popupContent.push('</div>');
    popupContent.push('<div style="clear: both"></div>');

    popupContent.push('<div>');
    popupContent.push('<p class="alignLeft">TAT:</p>');
    popupContent.push(`<p class="alignRight">${entryTime}</p>`);
    popupContent.push('</div>');
    popupContent.push('<div style="clear: both"></div>');

    popupContent.push('<br />');

    let geofenceData = JSON.parse(payload.geofence);

    if (geofenceData.length !== 0) {
      geofenceData = geofenceData.sort((a, b) => a.entryTimestamp - b.entryTimestamp);

      popupContent.push('<p class="header">Geofence Summary</p>');
      popupContent.push('<div>');

      popupContent.push('<ul _ngcontent-c0="" class="clr-timeline clr-timeline-vertical">');
    } else {
      popupContent.push('<p class="header">No Geofence data</p>');
    }

    for (let i = 0; i < geofenceData.length; i++) {
      const g = geofenceData[i];

      if (!g.exitTimestamp) {
        g.hasNotExit = true;
        g.exitTimestamp = new Date();
      }

      const diffRaw = moment.duration(moment(g.exitTimestamp).diff(g.entryTimestamp));
      const diff = diffRaw.humanize();
      let warning = 'green';

      if (diffRaw.asSeconds() / 60 > 30) {
        warning = 'orange';
      }

      if (diffRaw.asSeconds() / 60 > 90) {
        warning = 'red';
      }

      if (diffRaw.asSeconds() / 60 > 180) {
        warning = 'grey';
      }

      popupContent.push(`

        <li _ngcontent-c0="" class="clr-timeline-step">
        <div _ngcontent-c0="" class="clr-timeline-step-header">${moment(g.entryTimestamp).format('DD-MM-YY HH:mm:ss')}</div>
        <clr-icon _ngcontent-c0="" aria-label="Not started" shape="circle" style="color:${warning}">
        <svg version="1.1" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" focusable="false" aria-hidden="true" role="img">
        <path d="M18,34A16,16,0,1,1,34,18,16,16,0,0,1,18,34ZM18,4A14,14,0,1,0,32,18,14,14,0,0,0,18,4Z" class="clr-i-outline clr-i-outline-path-1"></path>
          </svg>
          </clr-icon>
          <div _ngcontent-c0="" class="clr-timeline-step-body">
        <span _ngcontent-c0="" class="clr-timeline-step-title">${g.geofence}</span>
        <span _ngcontent-c0="" class="clr-timeline-step-description">${diff} ${g.hasNotExit ? '<span> (current)</span>' : ''}</span>
        </div>
        </li>
    `);
    }
    popupContent.push('</ul>');
    return L.popup().setContent(popupContent.join(''));
  }

  generateLegend() {
    // @ts-ignore
    const legend = L.control({ position: 'bottomleft' });
    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'legend');
      div.innerHTML += '<img src="assets/img/leaflet/truck_green.svg" alt=""><span>Vehicle has experienced no violations.</span><br>';
      div.innerHTML +=
        '<img src="assets/img/leaflet/truck_orange.svg" alt=""><span>Vehicle has been delayed at one of the sites.</span><br>';
      div.innerHTML +=
        '<img src="assets/img/leaflet/truck_red.svg" alt=""><span>Vehicle has exceeded 90 minute allowance at one of the sites.</span><br>';
      div.innerHTML +=
        '<img src="assets/img/leaflet/truck_black.svg" alt=""><span>Vehicle has exceeded total time allowance in the yard ( > 180 minutes).</span><br>';
      return div;
    };
    return legend;
  }
}
